<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <el-form
            :model="form"
            label-position="top"
            label-width="120px"
        >
          <div class="row">
            <div class="col-8">
              <el-form-item>
                <el-input
                    @keyup.enter.native="fetchData"
                    name="search"
                    type="text"
                    v-model="form.searchInput"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col-4">
              <el-form-item>
                <projects-select-list
                    :allOption="true"
                    v-model="form.selectedProject"
                ></projects-select-list>
              </el-form-item>
            </div>
            <div class="col-8">
              <el-form-item>
                <el-checkbox
                    type="checkbox"
                    v-model="form.projects"
                >{{ $t('system.projects') }}
                </el-checkbox>
                <el-checkbox
                    type="checkbox"
                    v-model="form.issues"
                >{{ $t('system.issues') }}
                </el-checkbox>
                <el-checkbox
                    type="checkbox"
                    v-if="ifHasPermission(['payroll-all', 'payroll-own'])"
                    v-model="form.logs"
                >
                  {{ $t('system.logs') }}
                </el-checkbox>
              </el-form-item>
            </div>
            <div class="col-4 d-flex justify-content-end">
              <el-form-item>
                <el-button
                    @click="fetchData"
                    type="primary"
                >{{ $t('system.send') }}
                </el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <div
        class="view"
        v-if="form.projects"
    >
      <div class="view-heading">
        <div class="view-title">{{ $t('system.projects') }}</div>
      </div>
      <div class="view-body">
        <ul v-if="projects.data.length > 0">
          <li
              :key="item.id"
              v-for="item in projects.data"
          >
            <router-link
                :to="{ name: 'Overview', params: { projectId: item.id}}"
                class="font-weight-bold"
            >
              #{{ item.id }} {{ item.name }}
            </router-link>
          </li>
        </ul>
        <div v-else>
          <span class="no-date-title">{{ $t('system.no_data') }}</span>
        </div>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="projectsParams.page"
              :page-size="projectsParams.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="projects.total"
              @current-change="handleCurrentChangeProject"
              @size-change="handleSizeChangeProject"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
    </div>
    <div
        class="view"
        v-if="form.issues"
    >
      <div class="view-heading">
        <div class="view-title">{{ $t('system.issues') }}</div>
      </div>
      <div class="view-body">
        <ul v-if="issues.data.length > 0">
          <li
              :key="issue.id"
              v-for="issue in issues.data"
          >
            <router-link :to="{...$route, query: {issueId: issue.id}}">
              <span
                  @click="showModalTask(issue.id)"
                  class="underline_hover font-weight-bold"
              >
                #{{ issue.id }} {{ issue.subject }}
              </span>
            </router-link>
            (
            <router-link
                :key="project.id"
                :to="{ name: 'Overview', params: {projectId: project.id}}"
                v-for="(project, idx) in issue.pathWithNames"
            >
              <span class="underline_hover">{{ project.name }}</span>
              <template v-if="idx < issue.pathWithNames.length-1">
                &#10132;
              </template>
            </router-link>
            )
          </li>
        </ul>
        <div v-else>
          <span class="no-date-title">{{ $t('system.no_data') }}</span>
        </div>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="issuesParams.page"
              :page-size="issuesParams.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="issues.total"
              @current-change="handleCurrentChangeIssue"
              @size-change="handleSizeChangeIssue"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
    </div>
    <div
        class="view"
        v-if="form.logs"
    >
      <div class="view-heading">
        <div class="view-title">{{ $t('system.logs') }}</div>
      </div>
      <div class="view-body">
        <div v-if="logs.data.length > 0">
          <ul
              :key="item.id"
              v-for="item in logs.data"
          >
            <li v-if="item.issue">
              <router-link :to="{ name: 'Issue Show', params: { issueId: item.issue_id, projectId: item.issue.project_id}}">
                {{ item.issue.subject }}
              </router-link>
              {{ $t('time_entry.comments') }}: {{ item.comments }} {{ $t('system.date') }}: {{ item.created_at }}
            </li>
          </ul>
        </div>
        <div v-else>
          <span class="no-date-title">{{ $t('system.no_data') }}</span>
        </div>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="logsParams.page"
              :page-size="logsParams.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="logs.total"
              @current-change="handleCurrentChangeLogs"
              @size-change="handleSizeChangeLogs"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import _ from 'lodash';
import EventBus from '../../eventBus';
import projectsSelectList from '../../components/projectsTree/projectsSelectList.vue';
import notification from '../../notification/notify';

export default {
  name: 'GlobalSearch',
  components: {
    'projects-select-list': projectsSelectList,
  },
  data: function () {
    return {
      form: {
        projects: true,
        issues: true,
        logs: false,
        searchInput: '',
        selectedProject: null,
      },
      projectsTree: [],
      projects: {
        data: [],
        total: 0,
      },
      issues: {
        data: [],
        total: 0,
      },
      logs: {
        data: [],
        total: 0,
      },
      projectsParams: {
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
        search_column: 'id',
        search_operator: 'equal_to',
        search_query_1: '',
        search_query_2: '',
      },
      issuesParams: {
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
        search_column: 'id',
        search_operator: 'equal_to',
        search_query_1: '',
        search_query_2: '',
      },
      logsParams: {
        column: 'id',
        direction: 'desc',
        per_page: 10,
        page: 1,
        search_column: 'id',
        search_operator: 'equal_to',
        search_query_1: '',
        search_query_2: '',
      },
      lessThan3: false,
    };
  },
  computed: {},
  beforeMount() {
    EventBus.$on('CLOSE_ISSUE_MODAL', () => {
      this.fetchData();
    });
    this.form.searchInput = this.$store.state.searching;
    this.fetchData();
  },
  beforeDestroy() {
    EventBus.$off('CLOSE_ISSUE_MODAL');
  },
  mounted() {
    let that = this;
    this.$store.watch(function (state) {
      return state.searchClicked;
    }, function (searchParse) {
      that.form.searchInput = that.$store.state.searching;
      that.fetchData();
    });
  },
  methods: {
    showModalTask: function (id) {
      let query = _.cloneDeep(this.$route.query);
      query.issueId = id;
      this.$router.push({path: this.$route.path, query: query});
    },
    handleCurrentChangeProject(val) {
      this.projectsParams.page = val;
      this.fetchData();
    },
    handleSizeChangeProject(val) {
      this.projectsParams.per_page = val;
      this.fetchData();
    },
    handleCurrentChangeIssue(val) {
      this.issuesParams.page = val;
      this.fetchData();
    },
    handleSizeChangeIssue(val) {
      this.issuesParams.per_page = val;
      this.fetchData();
    },
    handleCurrentChangeLogs(val) {
      this.logsParams.page = val;
      this.fetchData();
    },
    handleSizeChangeLogs(val) {
      this.logsParams.per_page = val;
      this.fetchData();
    },
    fetchData() {
      if (this.form.searchInput.length < 3) {
        this.lessThan3 = true;
      } else {
        this.lessThan3 = false;
      }

      if (this.form.projects) {
        this.fetchProjects();
      } else {
        this.project = {
          data: [],
        };
      }
      if (this.form.issues) {
        this.fetchIssues();
      } else {
        this.issues = {
          data: [],
        };
      }
      if (this.form.logs) {
        this.fetchLogs();
      } else {
        this.logs = {
          data: [],
        };
      }
    },
    fetchProjects(searchParse) {
      let that = this;
      let searchVal = '';
      if (this.searchInput !== '') {
        searchVal = this.form.searchInput;
      } else {
        searchVal = searchParse;
      }
      axios.get('search_projects', {
        params: {
          searchParse: '"' + searchVal + '"',
          selectedProject: this.form.selectedProject,
          ...this.projectsParams,
        },
      })
          .then(function (response) {
            Vue.set(that.$data, 'projects', response.data.result.projects);
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    fetchIssues(searchParse) {
      let that = this;
      let searchVal = '';
      if (this.searchInput !== '') {
        searchVal = this.form.searchInput;
      } else {
        searchVal = searchParse;
      }
      axios.get('search_issues', {
        params: {
          searchParse: '"' + searchVal + '"',
          selectedProject: this.form.selectedProject,
          ...this.issuesParams,
        },
      })
          .then(function (response) {
            Vue.set(that.$data, 'issues', response.data.result.issues);
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    fetchLogs(searchParse) {
      let that = this;
      let searchVal = '';
      if (this.searchInput !== '') {
        searchVal = this.form.searchInput;
      } else {
        searchVal = searchParse;
      }
      axios.get('search_logs', {
        params: {
          searchParse: '"' + searchVal + '"',
          selectedProject: this.form.selectedProject,
          ...this.logsParams,
        },
      })
          .then(function (response) {
            Vue.set(that.$data, 'logs', response.data.result.logs);
          })
          .catch(function (error) {
            notification.notify(
                that.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },

    projectsNext() {
      if (this.projects.next_page_url) {
        this.projectsParams.page++;
        this.fetchProjects();
      }
    },
    projectsPrev() {
      if (this.projects.prev_page_url) {
        this.projectsParams.page--;
        this.fetchProjects();
      }
    },
    issuesNext() {
      if (this.issues.next_page_url) {
        this.issuesParams.page++;
        this.fetchIssues();
      }
    },
    issuesPrev() {
      if (this.issues.prev_page_url) {
        this.issuesParams.page--;
        this.fetchIssues();
      }
    },
    logsNext() {
      if (this.logs.next_page_url) {
        this.logsParams.page++;
        this.fetchLogs();
      }
    },
    logsPrev() {
      if (this.logs.prev_page_url) {
        this.logsParams.page--;
        this.fetchLogs();
      }
    },
  },
};
</script>

<style>

</style>
